<template>
  <div id="app" class="before-login-page">
    <div class="body fixed mobile">
      <div class="main">
        <img class="bg" src="@/assets/img/background.png" />
        <h2>{{ $t('before-login.title') }}</h2>
        <div class="content">
          <div class="logo-block">
            <img src="@/assets/images/img-TC-card.svg" />
            <p class="app-name">雲林幣扭一下</p>
          </div>
          <div class="box">
            {{  $t('before-login.content')  }}
          </div>
          <button class="button" @click="toLogin">
            <span class="fM">
              {{  $t('before-login.go-login')  }}
            </span>
          </button>
        </div>
        <div></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BeforeLogin',
  methods: {
    toLogin() {
      const config = {
        redirectUrl: `${process.env.VUE_APP_HOST}${process.env.BASE_URL}${
          this.$route.query.redirectPath || ""
        }`,
      }
      qcsso.login(config);
    },
  },
};
</script>

<style lang="scss" scoped>
.before-login-page {
  .main {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .content {
    margin-top: 10vh;
    position: relative;
    z-index: 1;
  }

  .app-name {
    color: #2C2A3E;
    font-size: 18px;
    font-weight: bold;
    margin-top: 6px;
  }

  .logo-block {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .box {
    margin-top: 20px;
    width: 70vw;
    max-width: 260px;
    padding: 20px;
    border-radius: 10px;
    background-color: #fff;
    color: #2C2A3E;
    font-size: 16px;
    line-height: 1.5;
    box-shadow: 0px 0px 20px rgba(55, 84, 170, 0.1);
  }

  .button {
    margin-top: 36px;
    color: #fff;
    border: none;
  }

  .bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    object-fit: cover;
  }

  .main {
    position: relative;
    overflow: hidden;
  }
}
</style>