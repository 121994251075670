<template>
  <div id="app" class="ecotableware-page">
    <div class="body fixed mobile">
      <Spinner :spin="spin" />
      <section class="header">
        <a class="icon iconButton third" @click.prevent="goBackTo">
          <font-awesome-icon
            icon="fa-solid fa-chevron-left"
            size="lg"
          ></font-awesome-icon>
        </a>
        <span class="fL">環保餐具租借與任務</span>
        <div class="icon iconButton"></div>
      </section>
      <section class="main">
        <div class="fork-card">
          <div
            class="fork-card-container"
            :title="link"
            @click="linkClickHandler(link)"
          >
            <img class="fork-bg" src="@/assets/img/fork.svg" />
            <p>
              <span>租借環保餐具</span>
              <font-awesome-icon
                icon="fa-solid fa-chevron-right"
                size="1x"
              ></font-awesome-icon>
            </p>
          </div>
        </div>
        <div class="section-wrapper">
          <div v-for="section in sections" :key="section.label" class="section-container">
            <p class="section-label">{{ section.label }}</p>
            <div class="section-cards">
              <RentalCard v-for="(card, i) in section.list" :key="`rental-card-${i}`" :data="card" />
            </div>
          </div>
        </div>
      </section>
    </div>
    <section id="popLeave" class="popup">
      <div class="popBody">
        <a class="close" @click.prevent="closeLeaveModal"
          ><font-awesome-icon icon="fas fa-times"></font-awesome-icon
        ></a>
        <section class="popHeader">
          <!-- <span class="fL">Title</span> -->
        </section>
        <section class="popMain">
          <div class="fL text-center">提示</div>
          <div class="icon"></div>
          <span class="">您即將離開 App 前往外部連結，請問是否繼續？</span>
        </section>
        <section class="popFooter flex">
          <a
            class="button bg-transparent text-black border border-primary flex-1 submit rounded"
            @click.prevent="closeLeaveModal"
            >返回</a
          >
          <a class="button flex-1 submit rounded" @click.prevent="goOut()"
            ><span class="fM">確認</span></a
          >
        </section>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState } from "vuex";

import Spinner from "@/components/Spinner";
import RentalCard from "@/components/RentalCard";

export default {
  name: "Ecotableware",
  components: {
    Spinner,
    RentalCard,
  },
  data() {
    return {
      apiHost: process.env.VUE_APP_API_HOST,
      merchantId: process.env.VUE_APP_MERCHANT_ID,
      spin: true,
      link: "https://bit.ly/3Yby7Z1",
      currentMonthTimes: 0,
      yearTimes: 0,
      monthLimit: 5,
      yearLimit: 50,
    };
  },
  computed: {
    ...mapState(["user", "userInfo"]),
    sections() {
      return [
        {
          label: "月任務",
          list: [
            {
              // departmentName: '環保局',
              // startTime: 1630489200,
              title: "每月租借使用",
              // description: 'asdasd',
              reward_point: 70,
              // reward_total: 1500,
              // reward_remain: 600,
              // reward_status: 0,
              mission_current_step: this.currentMonthTimes,
              mission_total_step: this.monthLimit,
            },
            // {
            //   departmentName: '環保局',
            //   startTime: 1630489200,
            //   title: '環保餐具租借任務2',
            //   description: 'asdasd',
            //   reward_point: 100,
            //   reward_total: 1500,
            //   reward_remain: 1000,
            //   reward_status: 1,
            //   mission_current_step: 2,
            //   mission_total_step: 5,
            // },
          ],
        },
        {
          label: "年任務",
          list: [
            {
              // departmentName: '環保局',
              // startTime: 1630489200,
              title: "年度租借使用",
              // description: 'asdasd',
              reward_point: 1000,
              // reward_total: 1500,
              // reward_remain: 600,
              // reward_status: 1,
              mission_current_step: this.yearTimes,
              mission_total_step: this.yearLimit,
            },
          ],
        },
      ];
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.getEcotableAPI()
        .then((res) => {
          this.spin = false;
          const { currentMonthTimes, yearTimes } = res.data;
          this.currentMonthTimes = currentMonthTimes > this.monthLimit ? this.monthLimit : currentMonthTimes;
          this.yearTimes = yearTimes > this.yearLimit ? this.yearLimit : yearTimes;
        })
        .catch(() => {
          this.spin = false;
        });
    },
    getEcotableAPI() {
      let config = {
        url: `${this.apiHost}/campaigns/v1/merchants/${this.merchantId}/eco_tableware/phone/${this.user.phone}`,
        methods: "GET"
      };
      return this.$http(config);
    },
    linkClickHandler(url) {
      this.openLeaveModal();
      this.link = url;
    },
    goOut() {
      this.closeLeaveModal();
      window.open(this.link, "_blank");
    },
    openLeaveModal() {
      const wrap = document.querySelector("#popLeave");
      wrap.style.display = "flex";
    },
    closeLeaveModal() {
      const wrap = document.querySelector("#popLeave");
      wrap.style.display = "none";
    },
    goBackTo() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped lang="scss">
.ecotableware-page {
  .main {
    padding: 84px 16px 90px;
  }

  .fork-card {
    height: 80px;
    border-radius: 20px;
    box-shadow: 0px 5px 16px 0px #160f250d;
  }

  .fork-card-container {
    position: relative;
    border-radius: 20px;
    overflow: hidden;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    p {
      text-align: right;
      margin-right: 16px;
      font-size: 20px;
      font-weight: 500;
      span {
        margin-right: 4px;
      }
    }
  }

  .fork-bg {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }

  .section-wrapper {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    gap: 32px;
  }

  .section-label {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 8px;
  }

  .section-cards {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
}
</style>
