<template>
  <div class="uide-mission-card card-wrapper">
    <div class="card-container">
      <!-- <div class="uide-mission-card-top">
          <div class="uide-mission-card-top-chip">{{ data.departmentName}}</div>
          <div>{{ tsToDateDay(data.startTime) }}</div>
      </div> -->
      <div class="uide-mission-card-title">
          {{ data.title}}
      </div>
      <div class="ellipsis-two-line">
          {{ parseContent(data.description) }}
      </div>
      <div class="flex gap-1 items-center mt-1">
          <img  width="20" height="20" src="@/assets/icon/uide/coin.svg" />
          <span>雲林幣{{ data.reward_point }}枚</span>
      </div>
      <div class="mission-step">
        <p>任務完成進度</p>
        <div class="mission-step-point" :class="stepComplete ? 'complete' : ''">
          <span>{{ data.mission_current_step }} / {{ data.mission_total_step }}</span>
        </div>
      </div>
      <!-- <div class="flex gap-1 items-center justify-between ">
          <ProgressBar  style="width:228px;" :progress="((data.reward_total - data.reward_remain) / data.reward_total) * 100"/>
          <div :class="[ 'uide-mission-card-progress-btn', 'status' + (data.reward_status ? '1' : '0') ]">{{ statusName }}</div>
      </div>
      <div class="flex gap-1 items-center ">
          <img  width="16" height="16" src="@/assets/icon/uide/people.svg" />
          <span class="uide-mission-card-user">獎勵剩餘人數 {{ data.reward_remain }} 名 / 總人數 {{ data.reward_total }} 名</span>
      </div> -->
      <!-- <img src="@/assets/icon/bell-v2.svg"
          :class="['uide-mission-card-bell', { hide: data.unread === false }]"
      /> -->
    </div>
  </div>
</template>

<script>
import moment from "moment-timezone";

// import ProgressBar from '@/components/ProgressBar.vue';

export default {
  components: {
    // ProgressBar
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      statusNames: {
        0: "快來搶獎勵",
        1: "已領取獎勵",
        2: "獎勵已搶完",
      },
    }
  },
  computed: {
    statusName() {
      return this.statusNames[this.getStatusId(this.data)] || "Unknown";
    },
    stepComplete() {
      return this.data.mission_current_step >= this.data.mission_total_step;
    }
  },
  methods: {
    parseContent(content) {
      return content?.replace(/(<([^>]+)>)/ig, "")
    },
    tsToDateDay(ts) {
      return moment(ts * 1000).format("YYYY/MM/DD");
    },
    getStatusId(data) {
      return data.hasCompleted ? 1 : (data.reward_remain > 0 ? 0 : 2)
    },
  }
}
</script>

<style scoped lang="scss">
.card-wrapper {
  padding: 0;
  margin: 0;

  .card-container {
    padding: 12px 16px;
  }

  .mission-step {
    display: flex;
    align-items: center;
    margin-top: 8px;
  }

  .mission-step-point {
    padding: 2px 8px;
    background-color: #E5E5E5;
    border-radius: 28px;
    margin-left: 8px;
    position: relative;
    display: flex;
    align-items: center;

    span {
      font-size: 14px;
      color: #000000;
    }

    &.complete {
      background-color: #FFC107;
    }
  }
}
</style>